import {firestore, collection, addDoc, serverTimestamp} from "./";
import {getAllTaskDocs} from "./getAllTasks";

const addTask = async (taskDescription: string, createdBy: string, department: string) => {
  try {
    const taskDocs = await getAllTaskDocs();
    const taskCount = taskDocs.docs.length;
    const d = Date.now();
    const dd = new Date(d);
    const loc = dd.toLocaleDateString("de-DE");
    if (taskCount > 0) {
      await addDoc(collection(firestore, "tasks"), {
        id: (taskCount+1).toString(),
        taskDescription: taskDescription,
        createdBy: createdBy,
        createdAt: loc,
        department: department,
        done: false,
        timestamp: serverTimestamp()
      })
    }
    return "Success adding new task"
  } catch (e) {
    return "Error in 'addTask'"
  }
}

export default addTask;