import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Calendar from "./pages/calendar/Calendar";
import Admin from "./pages/admin/Admin";
import TasksPage from "./pages/tasks/TasksPage";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path={"/"} element={<Calendar />} />
        <Route path={"/tasks"} element={<TasksPage />} />
        <Route path={"/admin"} element={<Admin />} />
      </Routes>
    </Router>
  )
}

export default App;