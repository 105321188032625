import React, {useState} from "react";
import {Box, Typography, Button, TextField} from "@mui/material"

import "./admin.css"
import {setuser} from "../../adaptors/firebase/setUser"

const Admin = () => {

  const [studentName, setStudentName] = useState<string>("");

  const handleClick = () => {
    console.log("setting new user", studentName)
    setuser(studentName)
      .then(resp => console.log(resp.id))
      .catch(e => console.log(e))
  }

  return (
    <Box className={"admin-root"}>
      <Typography variant={"h4"} sx={{mb: "30px"}}>Admin</Typography>
      <Typography variant={"h6"}>Werkstudent neu anlegen</Typography>
      <TextField
        onChange={({target}) => {setStudentName(target.value)}}
      ></TextField>
      <Button
        variant={"contained"}
        onClick={handleClick}
      >Neu Anlegen</Button>
    </Box>
  )
}

export default Admin;