import {firestore, query, collection, getDocs, where} from "./"

const getAllTaskDocs = async () => {
  const gquery = query(collection(firestore, "tasks"));
  return await getDocs(gquery);
}

const getAllTasks = async (): Promise<any[]> => {
  const gquery = query(
    collection(firestore,"tasks"),
    // where("id", "==", "task")
  )
  const querySnapshot = await getDocs(gquery);
  const docs = querySnapshot.docs;
  const tasks: any[] = docs.map(doc => {
    return doc.data();
  })
  return tasks
}

export {getAllTasks, getAllTaskDocs};