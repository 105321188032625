import {initializeApp} from "firebase/app"
import {getDoc, getDocs, setDoc, deleteDoc, updateDoc, arrayUnion, arrayRemove, limit, doc, addDoc, getFirestore, connectFirestoreEmulator, increment, orderBy, query, where, and, collection, serverTimestamp } from "firebase/firestore"

const firebaseConfig = {
  apiKey: "AIzaSyA80tDnznGQ6S6-FcmvaWvmdvQb_qctp5Y",
  authDomain: "sv-werkstudenten.firebaseapp.com",
  projectId: "sv-werkstudenten",
  storageBucket: "sv-werkstudenten.appspot.com",
  messagingSenderId: "789963466489",
  appId: "1:789963466489:web:741186e16bc6a8ca132428",
  measurementId: "G-N6WBZK9K19"
};

const app = initializeApp(firebaseConfig)
const firestore = getFirestore(app);

// connectFirestoreEmulator(firestore, "127.0.0.1", 8082);


export {firestore, getDoc, getDocs, deleteDoc, updateDoc, setDoc, arrayUnion, arrayRemove, limit, doc, addDoc, getFirestore, increment, orderBy, query, where, and, collection, serverTimestamp}