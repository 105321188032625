import React, {useEffect, useState} from "react";
import {Box, Paper, Typography, IconButton} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

import {getAllTasks} from "../../adaptors/firebase/getAllTasks";
import deleteTask from "../../adaptors/firebase/deleteTask";

interface Task {
  id: string,
  createdAt: string,
  createdBy: string,
  department: string,
  due: string,
  taskDescription: string
}

const TasksList = () => {

  const [allTasks, setAllTasks] = useState<Task[]>([]);

  useEffect(() => {
    getAllTasks()
      .then((resp) => {
        setAllTasks(resp)
      })
      .catch(e => console.log(e.message))
  }, []);

  const handleTaskDelete = (e: any) => {
    const id = e.target.closest(".paper").id;
    console.log(id);
    deleteTask(id)
      .then((resp) => {
        console.log(resp);
        const newAllTasks = allTasks;
        const taskIndex = newAllTasks.findIndex(task => task.id === id);
        const filteredTasks = newAllTasks.filter(task => task.id !== newAllTasks[taskIndex].id);
        console.log(filteredTasks);
        setAllTasks(filteredTasks);
      })
      .catch(e => console.log("Error in 'handleTaskDelete'", e.message))
  }

  return (
    <Box className={"tasks-list"}>
      <Typography sx={{mb: 2}} variant={"h4"}>Offene Aufgaben</Typography>
      {
        allTasks.map((task) => (
          <Paper key={task.id} id={task.id} className={"paper"}>
            <Typography className={"task-description"} variant={"body1"}>{task.taskDescription}</Typography>
            <Typography className={"created-by"} variant={"caption"}>{task.createdBy}</Typography>
            <Typography className={"created-at"} variant={"caption"}>{task.createdAt}</Typography>
            <div className={"delete-button"}>
              <IconButton
                onClick={handleTaskDelete}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </Paper>
        ))
      }
    </Box>
  )
}

export default TasksList;