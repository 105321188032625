import { firestore, collection, query, where, doc, deleteDoc, getDocs } from "./";

const deleteTask = async (id: string) => {
  try {
    const gquery = query(collection(firestore, "tasks"), where("id", "==", id));
    const taskDocs = await getDocs(gquery);
    const docId = taskDocs.docs[0].id;
    const deleteResponse = await deleteDoc(doc(firestore, "tasks", docId));
    console.log(deleteResponse);
    return "success"
  } catch (e: any) {
    return `Error in deleteTask: ${e.message}`
  }
}

export default deleteTask;