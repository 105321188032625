import {firestore, query, collection, doc, getDocs, setDoc, updateDoc, where, arrayUnion, arrayRemove} from "./"

interface UserDetails {
  username: string,
  days: number[]
}

const getDays = async (username: string, month: string, year: string): Promise<number[]> => {
  const gquery = query(collection(firestore, "presenceDates"),
    where("id", "==", username),
    where("month", "==", month),
    where("year", "==", year));
  const userDocs = await getDocs(gquery);
  if (userDocs.docs.length !== 0) {
    const userDoc: number[] = userDocs.docs[0]?.data().days;
    return userDoc;
  } else {
    return [];
  }
}

const getAllDays = async (month: string, year: string): Promise<UserDetails[]> => {
  const gquery = query(collection(firestore, "presenceDates"),
    where("month", "==", month),
    where("year", "==", year));
  const userDocs = await getDocs(gquery);
  let userDetails: UserDetails[] = [];
  // let allDays: [number[]] = [[]]
  // let allDays2: number[] = []
  userDocs.forEach(res => {
    const username: string = res.data().id;
    // res.data().days.forEach((day: number) => {
    //   allDays2.push(day);
      // if (!allDays2.includes(day)) {
      //   allDays2.push(day);
      // }
    // })
    const days: number[] = res.data().days;
    // allDays.push(days);
    const details: UserDetails = {
      username: username,
      days: days
    }
    userDetails.push(details)
  })
  return userDetails
}

const addDay = async (user: string, day: number, month: string, year: string) => {
  /**
   * Creates a new doc in firestore with id, year, month and day
   * or adds a day as a number to the corresponding doc.
   */
  try {
    const daysQuery = query(collection(firestore, "presenceDates"),
      where("id", "==", user),
      where("month", "==", month),
      where("year", "==", year)
    );
    const daysDocs = await getDocs(daysQuery);
    if (daysDocs.docs.length > 0) {
      const docId = daysDocs.docs[0].id;
      const docRef = doc(firestore, `presenceDates/${docId}`);
      await updateDoc(docRef, {
        days: arrayUnion(day)
      })
    } else {
      const docRef = doc(collection(firestore, "presenceDates"));
      await setDoc(docRef, {
        id: user,
        year: year,
        month: month,
        days: [day]
      });
    }
  } catch (e: any) {
    console.log("Error in 'addDay':", e.message)
  }
}

const removeDay = async (user: string, day: number, month: string, year: string) => {
  const daysQuery = query(collection(firestore, "presenceDates"),
    where("id", "==", user),
    where("month", "==", month),
    where("year", "==", year)
  );
  const daysDocs = await getDocs(daysQuery);
  if (daysDocs.docs.length > 0) {
    const docId = daysDocs.docs[0].id;
    const docRef = doc(firestore, `presenceDates/${docId}`);
    await updateDoc(docRef, {
      days: arrayRemove(day)
    });
  }
}

export {getDays, addDay, removeDay, getAllDays}