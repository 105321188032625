import React, {useEffect, useState, useRef} from 'react';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import './Calendar.css';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import {Button, LinearProgress, Box, Paper, List, ListItem, ListItemButton, ListItemText, Badge, Typography} from "@mui/material";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import 'dayjs/locale/de';  // This is important for displaying monday as first of week
import {getDays, addDay, removeDay, getAllDays} from "../../adaptors/firebase/firestoreGetDoc";
import {getAllUsers} from "../../adaptors/firebase/getAllUsers";
import dayjs, {Dayjs} from "dayjs";
import Header from "../../modules/Header";

dayjs.extend(utc);
// dayjs.extend(timezone);

interface UserDetails {
  username: string,
  days: number[]
}

let theme = createTheme({
  components: {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "transparent",
            color: "#000",
            fontWeight: "bold"
          },
          "&.Mui-selected:focus": {
            backgroundColor: "transparent",
          }
        },
      }
    }
  }
})

theme = createTheme(theme, {
  components: {
    // Following for adjusting the width and height
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          // TODO: Das war vorher maxHeight. Das hat aber nach dem Umbau des DOM Tree nicht mehr funktioniert
          //  Durch iwas wird das scheinbar beschnitten von einem aeusseren div/Box
          minHeight: "420px",
          width: "100%",
          [theme.breakpoints.up("md")]: {
            // TODO: Das war vorher maxHeight. Das hat aber nach dem Umbau des DOM Tree nicht mehr funktioniert
            //  Durch iwas wird das scheinbar beschnitten von einem aeusseren div/Box
            minHeight: "574px",
            width: "100%"
          }
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        header: {
          [theme.breakpoints.up("md")]: {
            justifyContent: "space-between"
          }
        },
        weekContainer: {
          padding: "5px 0",
          justifyContent: "space-between",
          [theme.breakpoints.up("md")]: {
            padding: "20px 0",
            justifyContent: "space-between"
          }
        },
        slideTransition: {
          minHeight: "380px",
          [theme.breakpoints.up("md")]: {
            minHeight: "480px"
          }
        }
      }
    },
  }
})

const HighligtedDay = (props: any) => {
  const { highlightedDays2 = {}, day, outsideCurrentMonth, ...other } = props;
  const daysStr: string[] = Object.keys(highlightedDays2);
  const days = daysStr.map(str => parseInt(str));
  const isSelected =
    !props.outsideCurrentMonth && days.indexOf(props.day.date()) >= 0;
  return (
    <Badge
      key={props.day.toString()}
      overlap="circular"
      badgeContent={isSelected ? highlightedDays2[props.day.date()] : undefined}
    >
      <PickersDay sx={isSelected ? {border: "1px solid rgba(0, 0, 0, 0.6)", background: "rgba(163,255,116,0.6) !important"} : {}} {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  );
}

function Calendar() {

  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [highlightedDays, setHighlightedDays] = useState<number[]>([]);
  const [highlightedDays2, setHighlightedDays2] = useState<any>({});
  const [werkstudenten, setWerkstudenten] = useState<string[]>([])
  const [werkstudentenDetails, setWerkstudentenDetails] = useState<UserDetails[]>([])
  const [availableWerkstudenten, setAvailableWerkstudenten] = useState<string[]>([])
  const [userName, setUserName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedMonth, setSelectedMonth] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [calendarEditTime, setCalendarEditTime] = useState<number>(100);
  const requestAbortController = useRef<AbortController>(null);

  useEffect(() => {
    getAllUsers()
      .then((resp) => {
        resp.unshift("all");
        setWerkstudenten(resp);
        setSelectedMonth((dayjs().month()+1).toString());
        setSelectedYear(dayjs().year().toString());
      })
  }, [])

  useEffect(() => {
    if (werkstudenten.length !== 0) {
      setUserName(werkstudenten[selectedIndex]);
      console.log("users from db loaded");
    } else {
      console.log("nothing loaded. waiting for users from database");
    }
  }, [werkstudenten, selectedIndex])

  useEffect(() => {
    if (userName !== "") {
      fetchHighlightedDays();
      setIsLoading(false);
    }
  }, [userName, selectedMonth]);

  const fetchHighlightedDays = (month?: string, year?: string) => {
    if (userName !== "") {
      const m: string = month ? month : selectedMonth;
      const y: string = year ? year : selectedYear;
      if (userName === "all") {
        getAllDays(m, y)
          .then((resp: UserDetails[]) => {
            setWerkstudentenDetails(resp)
            const allDays = resp.map(o => {
              return o.days;
            }).flat();
            let counter: any = {}
            for (const el of allDays) {
              if (counter[el]) {
                counter[el] += 1;
              } else {
                counter[el] = 1;
              }
            }
            setHighlightedDays(allDays);
            setHighlightedDays2(counter);
            setIsLoading(false);
          })
      } else {
        getDays(userName, m, y)
          .then((resp) => {
            let days: any = {}
            for (const el of resp) {
              days[el] = 1;
            }
            setHighlightedDays(resp);
            setHighlightedDays2(days);
            setIsLoading(false);
          })
      }
    } else {
      console.log("no user selected. no fetch")
    }
  }

  const handleDateChange = (e: any) => {
    console.log("remaining time to edit:", calendarEditTime)
    setAvailableWerkstudenten([]);
    const day: number = e["$D"];
    const month: string = selectedMonth;
    const year: string = selectedYear;
    if (calendarEditTime < 100) {
      if (werkstudenten.includes(userName) && userName !== "all") {
        // let newHighlightedDays: number[] = highlightedDays;
        let newHighlightedDays2: any = highlightedDays2;
        if (Object.keys(highlightedDays2).map(str => parseInt(str)).includes(day)) {
          // newHighlightedDays = highlightedDays.filter(val => val !== day);
          delete newHighlightedDays2[day]
          removeDay(userName, day, month, year)
            .then(_ => null)
            .catch(e => console.log("Error in removeDay:", e.message))
        } else {
          newHighlightedDays2[day] = 1;
          addDay(userName, day, month, year)
            .then(_ => null)
            .catch(e => console.log("Error in addDay", e.message))
        }
        setHighlightedDays2(newHighlightedDays2);

      } else {
        console.log("date change has no effect since no user selected")
      }
    } else if (calendarEditTime >= 100 && userName === "all") {
      console.log(day);
      const availableUsers = werkstudentenDetails
        .map(w => w.days.includes(day) ? w.username : "")
        .filter(el => el !== "");
      console.log(availableUsers);
      setAvailableWerkstudenten(availableUsers);
    }
  }

  const handleStudentChange = (index: number, user: string) => {
    setAvailableWerkstudenten([]);
    if (selectedIndex !== index) {
      setIsLoading(true);
      setSelectedIndex(index);
    }
  }

  const handleMonthChange = (date: any) => {
    setSelectedYear(date["$y"].toString());
    setSelectedMonth((date["$M"]+1).toString());
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }
    setIsLoading(true);
    setHighlightedDays([]);
  }

  //@ts-ignore
  const handleEditButtonClick = ({target}) => {
    console.log(target)
    target.setAttribute("disabled", "");
    const interval = setInterval(() => {
      setCalendarEditTime((oldProgress) => {
        if (oldProgress === 0) {
          clearTimeout(interval);
          return 100;
        }
        const diff: number = 1;
        return Math.max(oldProgress - diff, 0);
      });
    }, 300);
  }

  return (
    <Box className={"root-content"}>
      <Header title={"Kalender"} />
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale="de"  // This is important for displaying monday as first day of week
        // localeText={{
        //   calendarWeekNumberHeaderText: '#',
        //   calendarWeekNumberText: (weekNumber) => `${weekNumber}.`,
        // }}
      >
        <Typography variant={"h4"}>Werkstudenten Anwesenheitsliste</Typography>
        <Box className={"calendar-div"}>
          <Box className={"calendar"}>
            <Button
              onClick={handleEditButtonClick}
              disabled={calendarEditTime < 100}
            >Bearbeiten</Button>
            <Box sx={{width: "200px", height: "4px"}}>
              <LinearProgress variant={"determinate"} value={calendarEditTime} />
            </Box>
            <Box sx={{width: "100%"}}>
              <ThemeProvider theme={theme}>
                <DateCalendar
                  onChange={handleDateChange}
                  loading={isLoading}
                  renderLoading={() => <DayCalendarSkeleton />}
                  displayWeekNumber
                  // timezone="Europe/Paris"
                  onMonthChange={handleMonthChange}
                  slots={{day: HighligtedDay}}
                  slotProps={{
                    day: {
                      highlightedDays2,
                    } as any,
                  }}
                />
              </ThemeProvider>
            </Box>
          </Box>
          <Box className={"list"}>
            <Paper>{werkstudenten.map((ws: string, index: number): any => (
              <List>
                <ListItem
                  disablePadding
                  sx={availableWerkstudenten.includes(ws) ? {borderLeft: "1px solid #000"} : null}
                >
                  <ListItemButton
                    selected={selectedIndex === index}
                    onClick={() => {handleStudentChange(index, ws)}}
                  >
                    <ListItemText>
                      {ws}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </List>
            ))}
            </Paper>
          </Box>
        </Box>

        {/*<Box className="f-c-fs-c content">*/}
        {/*</Box>*/}

      </LocalizationProvider>
    </Box>
  );
}

export default Calendar;
