import {firestore, query, collection, getDocs} from "./"

const getAllUsers = async (): Promise<string[]> => {
  const gquery = query(collection(firestore, "users"));
  const userDocs = await getDocs(gquery);
  let users: string[] = [];
  userDocs.forEach((userDoc) => {
    users.push(userDoc.data().id);
  });
  return users;
}

export {getAllUsers}