import React from "react";
import {Box, Typography, Button} from "@mui/material";
import {Link} from "react-router-dom";

import "./header.css"

const Header = (props: any) => {

  return (
    <Box className={"header"}>
      <Box className={"header-menu"}>
        <Link to={"/"}>
          <Button>
            Kalender
          </Button>
        </Link>
        <Link to={"/tasks"}>
          <Button>
            Aufgaben
          </Button>
        </Link>
      </Box>
      <Typography variant={"h2"}>{props.title}</Typography>
    </Box>
  )
}

export default Header;