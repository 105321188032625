import React, {useState} from "react";
import {Box, TextField, Button, MenuItem, Typography} from "@mui/material";
import {useFormik} from "formik";

import tasksValidationsSchema from "./tasksValidationsSchema";
import addTask from "../../adaptors/firebase/addTask";
const TasksForm = (): React.ReactElement => {

  const [taskInputs, setTaskInputs] = useState<any[]>([
    {
      id: "taskDescription",
      value: "",
      select: false,
      label: "Beschreibung",
      placeholder: "",
      error: false,
      helperText: ""
    },
    {
      id: "createdBy",
      value: "",
      select: false,
      label: "Ersteller",
      placeholder: "",
      error: false,
      helperText: ""
    },
    {
      id: "department",
      value: "mechanic",
      select: true,
      selectValues: [{value: "mechanic", label: "Mechanik"}, {value: "electronic", label: "Elektronik"}],
      label: "Abteilung",
      placeholder: "",
      error: false,
      helperText: ""
    },
  ]);

  const formik = useFormik({
    initialValues: {taskDescription: "", createdBy: "", department: "mechanic"},
    validationSchema: tasksValidationsSchema,
    onSubmit: values => handleSubmit(values)
  })

  const handleSubmit = (values: any) => {
    console.log(formik.touched, formik.errors);
    console.log(values);
    addTask(values.taskDescription, values.createdBy, values.department)
      .then(resp => console.log(resp))
      .catch(e => console.log(e.message))
  }

  return (
    <Box className={"tasks-form-wrapper"}>
      <Typography sx={{mb: 2}} variant={"h4"}>Neue Aufgabe</Typography>
      <form className={"tasks-form"} onSubmit={formik.handleSubmit}>
        {taskInputs.map((input) => {
          return (
            <TextField
              key={input.id}
              id={input.id}
              label={input.label}
              select={input.select}
              //@ts-ignore
              error={formik.touched[input.id] && Boolean(formik.errors[input.id])}
              //@ts-ignore
              helperText={formik.touched[input.id] && formik.errors[input.id]}
              {...formik.getFieldProps(input.id)}
            >
              {input.select && input.selectValues.map((item: any) => (<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>))}
            </TextField>)
        })}
        <Button type={"submit"} variant={"contained"}>Aufgabe anlegen</Button>
      </form>

    </Box>
)
}



export default TasksForm;