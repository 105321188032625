import React from "react";
import {Box} from "@mui/material"

import Header from "../../modules/Header";
import TasksForm from "./TasksForm";
import TasksList from "./TasksList";
import "./tasks.css"


const TasksPage = (): React.ReactElement => {

  return (
    <Box className={"root-content"}>
      <Header title={"Aufgaben"} />
      <Box className={"tasks-root"}>
        <TasksForm />
        <TasksList />
      </Box>
    </Box>
  )
}

export default TasksPage;